import { useEffect, useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Form } from "react-bootstrap";
import '../css/bootstrap.min.css'
import { Navigate, useNavigate } from "react-router";
import { MDBBtn } from "mdb-react-ui-kit";
import { HelmetProvider } from "react-helmet-async";
import { useLogin } from "../../AdminApp/hooks/useLogin";
import config from "../../config";
import { useAuthContext } from "../../AdminApp/hooks/useAuthContext";
import PulseLoader from "../../AdminApp/components/MiniLoader/PulseLoader";


const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-admin-main);
    width: 100%;
    height: 100vh;
`
const LoginTitle = styled.h2``

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 30px;
    border-radius: 15px;
    max-width: 1500px;
    background-color: rgb(243, 244, 244);
    overflow-x: hidden;
    border: 1px lightgray solid;
    box-shadow: 0px 5px 20px 1px lightgray;
    display: flex;
    flex-direction: column;
    gap: 10px;

`
const ErrorDiv = styled.p`
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
`

const LoginFunction = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { login, error, isLoading } = useLogin()
    const navigate = useNavigate()

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        const loginSuccess = await login(email, password);
        if (loginSuccess) {
            navigate('/admin');
        }
    };

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta property="og:title" content="Login" />

                    <title> Menu Admin Login </title>

                    <meta charSet="UTF-8" />
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                </Helmet>
            </HelmetProvider>
            <Container>
                <Wrapper >
                    <LoginTitle>
                        Menu Admin Login
                    </LoginTitle>
                    <Form onSubmit={handleLoginSubmit}>
                        <Form.Group>
                            <Form.Label htmlFor="">
                                Email
                                <Form.Control type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                            </Form.Label>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="">
                                Password
                                <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} value={password} />
                            </Form.Label>
                        </Form.Group>
                        <MDBBtn onClick={handleLoginSubmit} type="submit" disabled={isLoading} >
                            Login
                        </MDBBtn>
                        {error && <ErrorDiv>{error}</ErrorDiv>}
                    </Form>
                </Wrapper>
            </Container>
        </>
    )
}
const Login = () => {
    const { user, dispatch } = useAuthContext()
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [isLoadingCheckToken, setIsLoading] = useState(true);
    const [errorCheckToken, setError] = useState('');

    const fetchToken = async () => {
        try {
            console.log('sending request')
            const response = await fetch(`${config.menuApi}/check-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId: user.id }),
                credentials: 'include'
            });
            const json = await response.json();
            if (response.ok) {
                setIsTokenValid(true);
                setError('');
                console.log('token checked')
                localStorage.setItem('user', JSON.stringify(json))
                dispatch({ type: 'LOGIN', payload: json })
            } else {
                throw new Error(json.error);
            }
        } catch (error) {
            setIsTokenValid(false);
            setError(error.message);
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchToken()
    }, []);


    if (isLoadingCheckToken && !isTokenValid) {
        return <PulseLoader />
    }
    if (!isLoadingCheckToken && isTokenValid) {
        return <Navigate to='/admin' />
    }
    if (!isLoadingCheckToken && (!isTokenValid || errorCheckToken)) {
        return <LoginFunction />
    }
}

export default Login;