import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { Suspense, lazy } from "react";
import Login from './App/pages/Login';

import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'bootstrap/dist/js/bootstrap.min.js'
import PageLoader from './App/components/PageLoader/PageLoader';
import config from './config';
import { useAuthContext } from './AdminApp/hooks/useAuthContext';

const App = lazy(() => import("./App/App"));
const AdminApp = lazy(() => import("./AdminApp/AdminApp"));

const Root = () => {
    const { user } = useAuthContext()
    
    const refreshServer = async () => {
        try {
            const response = await fetch(`${config.menuApi}/refresh`,{
                method:'GET',
            })
            const json = await response.json()
            if (response.ok) {
                console.log(json.message)
            }
            if (!response.ok) {
                throw Error(json.error)
            }
        } catch (error) {
            console.log(error)
        }
    }

    setInterval(() => {
        refreshServer()
    }, 600000)
    


    return (
        <>
            <Suspense fallback={<PageLoader />}>
                <Router>
                    <Routes>
                        <Route path="/*" element={<App/>} >
                            <Route path='*' element={<App/>} />
                        </Route>
                        <Route exact path='login' element={<Login/>} />
                        {
                            user && (
                                <Route path='/admin/*' element={<AdminApp />} >
                                    <Route path="*" element={<AdminApp />} />
                                </Route>
                            )
                        }
                    </Routes>
                </Router>
            </Suspense>
        </>
    )
}
export default Root;