import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import config from "../../config";
import axios from "axios";

export const useLogin = () => {
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { dispatch } = useAuthContext();

    const login = async (email, password) => {
        try {
            // Loading state condition and error condition
            setIsLoading(true);
            setError('');

            const response = await axios.post(`${config.menuApi}/auth-user/login-user`, {
                email,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            const json = await response.data.user;
            // Assuming the token is included in the response data
            const token = await response.data.token;

            if (!token) {
                throw new Error('Authentication token not provided');
            }

            // SAVE TOKEN AND USER TO LOCAL STORAGE
            localStorage.setItem('user', JSON.stringify(json));
            localStorage.setItem('loginToken', token);

            // UPDATE AUTH CONTEXT
            dispatch({ type: 'LOGIN', payload: json });

            setIsLoading(false);
            return true;
        } catch (error) {
            setIsLoading(false);
            setError(error.response?.data?.error || error.message);
            console.error('Error logging in:', error);
            return false;
        }
    };

    return { login, isLoading, error };
};
