import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthContextProviderD } from './AdminApp/context/AuthContext';
import Root from './Root';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthContextProviderD>
    <React.StrictMode>
      <Root />
    </React.StrictMode>
  </AuthContextProviderD>
);

reportWebVitals();