//IMPORTING IMAGES
function importAllApp(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}
const appImages = importAllApp(require.context('./App/imgs', true, /\.(png|jpe?g|svg|gif|mp4)$/));

const config = {
    menuApi: process.env.REACT_APP_MENU_API,
    companyName: 'Scent Hookah Lounge',
    companyLogo: appImages['logo.png'],
    favIcon: '',
    siteDirection: '',
    meta: {
        main: {
            title: 'Scent Hookah Lounge',
            description: "It is the beginning of a series of modern Arabic cafes to meet all desires .. Characterized as keeping pace with the new era For the cafeterias while adhering to a purely Arab reality and oriental customer taste Especially with modern drinks that professionally meet all Desires and tastes.",
            keywords: "Scent,scenthookahlounge, scent hookah lounge, hookah, lounge, cafe, coffee, restaurant,food, ramadan, iftar,suhoor, سينت, سينت هوكاه لاونج, مطعم, كافيه",
            image: appImages['logo.png'],
            url: 'https://scent.4design-adv.com',
        },
        home: {
            title: '',
            description: '',
            keywords: '',
            image: '',
            url: 'https://scent.4design-adv.com/',
        },
        contact: {
            title: 'Contact Us | تواصل معنا',
            description: '',
            keywords: '',
            image: '',
            url: 'https://scent.4design-adv.com/contact',
        },
        about: {
            title: 'About Us | عنا',
            description: '',
            keywords: '',
            image: '',
            url: 'https://scent.4design-adv.com/about',
        },
        menus: {
            title: 'Our Menus | منيوهاتنا',
            description: '',
            keywords: '',
            image: '',
            url: 'https://scent.4design-adv.com/menus',
        },
    },
    homeSlides: [
        {
            title: 'Welcome To Scent',
            description: 'A beginning of a series of modern Arabic cafes to meet all desires.',
            backgroundImage: appImages['slider/image-1.jpeg'],
            link: '/link-1'
        },
        {
            title: 'Classic Indoor Area',
            description: 'Characterized as keeping pace with the new era For the cafeterias while adhering to a purely Arab reality and oriental customer taste Especially with modern drinks that professionally meet all Desires and tastes.',
            backgroundImage: appImages['slider/image-2.jpeg'],
            link: '/link-1'
        },
        {
            title: 'Comfort Open Air Area',
            description: 'A beginning of a series of modern Arabic cafes to meet all desires.',
            backgroundImage: appImages['slider/image-3.jpeg'],
            link: '/link-1'
        },
        {
            title: 'Enjoy With your Special Design',
            description: 'Characterized as keeping pace with the new era For the cafeterias while adhering to a purely Arab reality and oriental customer taste Especially with modern drinks that professionally meet all Desires and tastes.',
            backgroundImage: appImages['slider/image-4.jpeg'],
            link: '/link-1'
        },

    ],
    contact: {
        hotline: [],
        phones: [
            {
                phone: '201099915022',
                link: 'tel:201099915022'
            }
        ],
        emails: [
            {
                name: 'info',
                email: 'info@scenthookalounge.com'
            }
        ],
        addresses: [
            'Meraki Complex, 17 Al Mahrouki street, next to the Air force House, Nasr City, Egypt',
        ],
        embedMapLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6519.20930088829!2d31.204196217589605!3d30.053192339878212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458412feca0581f%3A0x65f99fc51a0bb925!2sSalah%20El-Deen%20Mostafa%2C%20Ad%20Doqi%2C%20Dokki%2C%20Giza%20Governorate%203751323!5e0!3m2!1sen!2seg!4v1717255551900!5m2!1sen!2seg',
        socialMedia: {
            facebook: 'https://www.facebook.com/profile.php?id=100083776279791',
            instagram: 'https://www.instagram.com/scentloungecafe/?igshid=YmMyMTA2M2Y%3D',
            x: '',
            linkedin: '',
            pinterest: '',
            behance: '',
            dribble: '',
            snapchat: '',
            tiktok: '',
        },
    },

    headerBackgroundImage: '',
    headerBackgroundColor: '',

    footerBackgroundColor: '',
    footerBackgroundImage: ''
}

export default config;