import { MDBSpinner } from 'mdb-react-ui-kit'
import React from 'react'
import styled from 'styled-components'

const FullPage = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`
const PulseLoader = () => {
    return (
        <FullPage>
            <MDBSpinner grow style={{ width: '3rem', height: '3rem' }}>
                <span className='visually-hidden'>Loading...</span>
            </MDBSpinner>
        </FullPage>
    )
}

export default PulseLoader